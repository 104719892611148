export default {
    refs: {
        url: 'refs',
        title: 'Справочники',
        type: 'vertical',
        perms: [
            'v1.contractors.create',
            'v1.orders_payments_type.create',
            'v1.vehicle_type.create',
            'v1.cargo_type.create',
            'v1.cargo_unit.create',
            'v1.vehicle_loading_type.create',
            'v1.vehicle_unloading_type.create',
            'v1.move_directions.create',
            'v1.loading_points.create',
            'v1.organizations.create',
            'v1.unloading_points.create',
            'v1.vehicle_load_capacity.create',
        ],
        items: {
            legal_entity: {
                url: 'legal_entity',
                title: 'Юр.лица',
                perms: {
                    create: 'v1.legal_entity.create',
                },
            },
            vehicle_type: {
                url: 'vehicle_type',
                title: 'Типы транспорта',
                perms: {
                    create: 'v1.vehicle_type.create',
                },
            },
            cargo_type: {
                url: 'cargo_type',
                title: 'Типы груза',
                perms: {
                    create: 'v1.cargo_type.create',
                },
            },
            cargo_unit: {
                url: 'cargo_unit',
                title: 'Единица измерения груза',
                perms: {
                    create: 'v1.cargo_unit.create',
                },
            },
            vehicle_loading_type: {
                url: 'vehicle_loading_type',
                title: 'Тип загрузки ТС',
                perms: {
                    create: 'v1.vehicle_loading_type.create',
                },
            },
            vehicle_unloading_type: {
                url: 'vehicle_unloading_type',
                title: 'Тип выгрузки ТС',
                perms: {
                    create: 'v1.vehicle_unloading_type.create',
                },
            },
            orders_payments_type: {
                url: 'orders_payments_type',
                title: 'Форма оплаты',
                perms: {
                    create: 'v1.orders_payments_type.create',
                },
            },
            cargo_names: {
                url: 'cargo_names',
                title: 'Наименования груза',
                perms: {
                    create: 'v1.cargo_names.create',
                },
            },
            method_of_loading: {
                url: 'method_of_loading',
                title: 'Методы погрузки',
                perms: {
                    create: 'v1.method_of_loading.create',
                },
            },
            vehicle_load_capacity: {
                url: 'vehicle_load_capacity',
                title: 'Грузоподъемность ТС',
                perms: {
                    create: 'v1.vehicle_load_capacity.create',
                },
            },
            vehicle_order_statuses: {
                url: 'vehicle_order_statuses',
                title: 'Статусы ТС',
                perms: {
                    create: 'v1.vehicle_order_statuses.create',
                },
            },
            contractors: {
                url: 'contractors',
                title: 'Контрагенты',
                perms: {
                    create: 'v1.contractors.create',
                },
            },
            loading_points: {
                url: 'loading_points',
                title: 'Пункты загрузки',
                perms: {
                    create: 'v1.loading_points.create',
                },
            },
            unloading_points: {
                url: 'unloading_points',
                title: 'Пункты выгрузки',
                perms: {
                    create: 'v1.unloading_points.create',
                },
            },
            move_directions: {
                url: 'move_directions',
                title: 'Маршруты',
                perms: {
                    create: 'v1.move_directions.create',
                },
            },
            vat_rates: {
                url: 'vat_rates',
                title: 'Ставка НДС',
                perms: {
                    create: 'v1.vat_rates.create',
                },
            },
            expenses_types: {
                url: 'expenses_types',
                title: 'Статьи расходов',
                perms: {
                    create: 'v1.expenses.create',
                },
            },
            manufacture: {
                url: 'manufacture',
                title: 'Производители',
                perms: {
                    create: 'v1.manufacturers.create',
                },
            },
            providers: {
                url: 'providers',
                title: 'Поставщики',
                perms: {
                    create: 'v1.providers.create',
                },
            },
            types_of_nodes: {
                url: 'types_of_nodes',
                title: 'Виды узлов',
                perms: {
                    create: 'v1.types_of_nodes.create',
                },
            },
            node_positions: {
                url: 'node_positions',
                title: 'Позиция узлов',
                perms: {
                    create: 'v1.node_positions.create',
                },
            },
            node_report: {
                url: 'node_report',
                title: 'Отчёт по узлам',
                perms: {
                    create: 'v1.node_positions.create',
                },
            },
        },
    },
    settings: {
        url: 'settings',
        title: 'Настройки',
        type: 'vertical',
        perms: [
            'v1.cost_tonne_per_km.list',
        ],
        items: {
            cost_tonne_per_km: {
                url: 'cost_tonne_per_km',
                title: 'Оплата за тонно-километр',
                perms: {
                    create: 'v1.cost_tonne_per_km.create',
                },
            },
            cost_immobility: {
                url: 'cost_immobility',
                title: 'Оплата за простой',
                perms: {
                    create: 'v1.cost_immobility.create',
                },
            },
            daily_payment: {
                url: 'daily_payment',
                title: 'Ежедневная оплата',
                perms: {
                    create: 'v1.daily_payment.create',
                },
            },
            on_load_payment: {
                url: 'on_load_payment',
                title: 'Оплата за загрузку',
                perms: {
                    create: 'v1.on_load_payment.create',
                },
            },
            coefficient_income: {
                url: 'coefficient_income',
                title: 'Коэффициент начислений',
                perms: {
                    create: 'v1.coefficient_income.create',
                },
            },
            average_fuel_consumption: {
                url: 'fuel_consumption',
                title: 'Средний расход ГСМ',
                perms: {
                    create: 'v1.fuel_consumption.create',
                },
            },
            average_fuel_cost: {
                url: 'fuel_cost',
                title: 'Средняя стоимость ГСМ',
                perms: {
                    create: 'v1.fuel_cost.create',
                },
            },
        },
    },
    carriers: {
        url: 'carriers',
        title: 'Перевозчики',
        type: 'vertical',
        perms: [
            'v1.carriers.create',
        ],
        items: {
            list: {
                url: 'list',
                title: 'Перевозчики',
                perms: {},
            },
            applications: {
                url: 'applications',
                title: 'Заявки на регистрацию',
                perms: {},
            },
        },
    },
    orders: {
        url: 'orders',
        title: 'Заказы',
        type: 'vertical',
        perms: [
            'v1.orders.own.list',
            'v1.orders.list',
            'v1.orders_moderate.list',
            'v1.order_bids.list',
        ],
        items: {
            list: {
                url: 'list',
                title: 'Заказы',
                type: 'horizontal',
                perms: {
                    list: 'v1.orders.list',
                },
                items: {
                    'on_moderate': {
                        url: 'on_moderate',
                        title: 'На модерации',
                        perms: {},
                    },
                    'opened_bids': {
                        url: 'opened_bids',
                        title: 'На исполнении',
                        perms: {},
                    },
                    'closed_bids': {
                        url: 'closed_bids',
                        title: 'Закрытые заявки',
                        perms: {},
                    },
                    'completed': {
                        url: 'completed',
                        title: 'Завершенные',
                        perms: {},
                    },
                },
            },
            moderation: {
                url: 'moderation',
                title: 'Заказы',
                type: 'horizontal',
                perms: {
                    list: 'v1.orders_moderate.list',
                },
                items: {
                    'on_moderate': {
                        url: 'on_moderate',
                        title: 'На модерации',
                        perms: {},
                    },
                    'opened_bids': {
                        url: 'opened_bids',
                        title: 'На исполнении',
                        perms: {},
                    },
                    'closed_bids': {
                        url: 'closed_bids',
                        title: 'Закрытые заявки',
                        perms: {},
                    },
                    'completed': {
                        url: 'completed',
                        title: 'Завершенные',
                        perms: {},
                    },
                },
            },
            bids: {
                url: 'bids',
                title: 'Отклики на заказы',
                perms: {
                    list: 'v1.orders_moderate.list',
                },
            },
        },
    },
    users: {
        url: 'users',
        title: 'Пользователи',
        type: 'vertical',
        perms: [
            'v1.users.list',
            'v1.roles.list',
            'v1.perms.list',
        ],
        items: {
            list: {
                url: 'list',
                title: 'Пользователи',
                perms: {
                    create: 'v1.users.create',
                },
            },
            roles: {
                url: 'roles',
                title: 'Роли',
                perms: {
                    create: 'v1.roles.create',
                },
            },
            perms: {
                url: 'perms',
                title: 'Права',
                perms: {
                    create: 'v1.perms.create',
                },
            },
            actions: {
                url: 'actions',
                title: 'Последняя активность',
                perms: {
                    create: 'v1.users.list',
                },
            },
        },
    },
    organization: {
        url: 'organization',
        title: 'Организация',
        type: 'horizontal',
        perms: [
            'v1.vehicles.list',
            'v1.trailers.list',
            'v1.drivers.list',
        ],
        items: {
            couplings: {
                url: 'couplings',
                title: 'Сцепки',
                perms: {},
            },
            vehicles: {
                url: 'vehicles',
                title: 'Транспорт',
                perms: {},
            },
            trailers: {
                url: 'trailers',
                title: 'Прицепы',
                perms: {},
            },
            drivers: {
                url: 'drivers',
                title: 'Водители',
                perms: {},
            },
            orders: {
                url: 'orders',
                title: 'Заказы',
                type: 'horizontal',
                perms: {},
                items: {
                    'in_execution': {
                        url: 'in_execution',
                        title: 'В работе',
                    },
                    'allowed': {
                        url: 'allowed',
                        title: 'Завершён',
                    },
                    'denied': {
                        url: 'denied',
                        title: 'Завершён с возвратом',
                    },
                },
            },
            reports: {
                url: 'reports',
                title: 'Отчеты',
                perms: {},
                items: {
                    statuses: {
                        url: 'statuses',
                        title: 'По статусам',
                    },
                    vehicles: {
                        url: 'vehicles',
                        title: 'По машинам',
                    },
                    drivers: {
                        url: 'drivers',
                        title: 'По водителям',
                    },
                    contractors: {
                        url: 'contractors',
                        title: 'По контрагентам',
                    },
                    orders: {
                        url: 'orders',
                        title: 'По рейсам',
                    },
                    total: {
                        url: 'total',
                        title: 'Вал',
                    },
                    total_driver: {
                        url: 'total_driver',
                        title: 'Вал водитель',
                    },
                    accounting_fuel: {
                        url: 'accounting_fuel',
                        title: 'Учёт ГСМ',
                    },
                    drivers_accruals : {
                        url: 'drivers_accruals',
                        title: 'По начислениям водителям',
                    },
                },
            },
            finance: {
                url: 'finance',
                title: 'Финансы',
                perms: {},
            },
            inventory: {
                url: 'inventory',
                title: 'Мат.база',
                type: 'vertical',
                perms: {},
                items: {
                    inventory_nodes: {
                        url: 'inventory_nodes',
                        title: 'Узлы',
                        perms: {
                            create: 'v1.inventory_nodes.create',
                        },
                        items: {
                            all: {
                                url: 'all',
                                title: 'Все',
                                perms: {},
                            },
                            vehicles: {
                                url: 'vehicles',
                                title: 'На транспорте',
                                perms: {},
                            },
                            trailers: {
                                url: 'trailers',
                                title: 'На прицепах',
                                perms: {},
                            },
                            store: {
                                url: 'store',
                                title: 'Склад',
                                perms: {},
                            },
                        },
                    },
                    manufacture: {
                        url: 'manufacture',
                        title: 'Производители',
                        perms: {
                            create: 'v1.manufacturers.create',
                        },
                    },
                    providers: {
                        url: 'providers',
                        title: 'Поставщики',
                        perms: {
                            create: 'v1.providers.create',
                        },
                    },
                    types_of_nodes: {
                        url: 'types_of_nodes',
                        title: 'Виды узлов',
                        perms: {
                            create: 'v1.types_of_nodes.create',
                        },
                    },
                    node_positions: {
                        url: 'node_positions',
                        title: 'Позиция узлов',
                        perms: {
                            create: 'v1.node_positions.create',
                        },
                    },
                    node_report: {
                        url: 'node_report',
                        title: 'Отчёт по узлам',
                        perms: {
                            create: 'v1.node_positions.create',
                        },
                    },
                },
            },
        },
    },
};
